import { AUTH_STORAGE_KEYS, navigationUtils } from '@abatech/api';

import { HeartbeatDTO } from '../core/heartbeat';
import createTimer, { OnTimeout } from '../infrastructure/createTimer';
import fetchHeartbeat from '../infrastructure/fetchHeartbeat';
import logger from '../infrastructure/logger';

const sessionTimer = createTimer();

const defaultTimetoutHandler: OnTimeout = () => {
  logger.warn('Session expired, forcing logout');
  navigationUtils.handleLogoutRedirection('sessionExpired');
};

const isAuthenticated = (): boolean => !!localStorage.getItem(AUTH_STORAGE_KEYS.authToken);

const setupValidateHeartbeat = (onTimeout: OnTimeout) => {
  const handleHeartbeatError = (error): boolean => {
    logger.error(error);
    onTimeout();

    return false;
  };

  const startAutoLogout = (expirationTime: number): void => {
    const timeout = expirationTime - Date.now();
    sessionTimer.start(onTimeout, timeout);
  };

  const validateAutoLogout = ({ exp }: HeartbeatDTO): boolean => {
    const isExpired = Date.now() >= exp;

    if (isExpired) {
      const error = new Error('The session has expired');
      return handleHeartbeatError(error);
    }

    startAutoLogout(exp);

    return true;
  };

  const validateHeartbeat = (): Promise<boolean> =>
    fetchHeartbeat().then(validateAutoLogout).catch(handleHeartbeatError);

  return validateHeartbeat;
};

const startSessionMonitor = (onTimeout: OnTimeout = defaultTimetoutHandler): Promise<boolean> => {
  const validateHeartbeat = setupValidateHeartbeat(onTimeout);

  return isAuthenticated() ? validateHeartbeat() : Promise.resolve(false);
};

export default startSessionMonitor;

export { sessionTimer };
